import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faBell, faChevronRight, faChevronUp, faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import {
  BannerModule,
  BreadcrumbsModule,
  FormModule,
  GenericCarouselModule,
  GenericListModule,
  PipesModule,
} from '@motivforce/mx-library-angular';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AccentRowComponent } from './components/accent-row/accent-row.component';
import { ActionBoxComponent } from './components/action-box/action-box.component';
import { BackToLinkComponent } from './components/back-to-link/back-to-link.component';
import { BackToTopButtonComponent } from './components/back-to-top-button/back-to-top-button.component';
import { CarouselSliderComponent } from './components/carousel-slider/carousel-slider.component';
import { CustomDistributorsSearchListComponent } from './components/custom-distributors-search-list/custom-distributors-search-list.component';
import { CustomListComponent } from './components/custom-list/custom-list.component';
import { CustomProductListComponent } from './components/custom-product-list/custom-product-list.component';
import { CustomSalesClaimingListComponent } from './components/custom-sales-claiming-list/custom-sales-claiming-list.component';
import { CustomSelectFormControlComponent } from './components/custom-select-form-control/custom-select-form-control.component';
import { CustomTransactionsListComponent } from './components/custom-transactions-list/custom-transactions-list.component';
import { DefaultMainLayoutComponent } from './components/default-main-layout/default-main-layout.component';
import { DownloadProgramGuideComponent } from './components/download-program-guide/download-program-guide.component';
import { HomeBannerSectionComponent } from './components/home-banner-section/home-banner-section.component';
import { ImageSliderPuzzleComponent } from './components/image-slider-puzzle/image-slider-puzzle.component';
import { InfoSignBoxComponent } from './components/info-sign-box/info-sign-box.component';
import { InvoiceUploadFormComponent } from './components/invoice-upload-form/invoice-upload-form.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { LogoComponent } from './components/logo/logo.component';
import { ModalOpenPaddingWrapperComponent } from './components/modal-open-padding-wrapper/modal-open-padding-wrapper.component';
import { PromotionAnimatedButtonComponent } from './components/promotion-animated-button/promotion-animated-button.component';
import { PromotionBoxComponent } from './components/promotion-box/promotion-box.component';
import { SalesAcceleratorListComponent } from './components/sales-accelerator-list/sales-accelerator-list.component';
import { SalesTargetListComponent } from './components/sales-target-list/sales-target-list.component';
import { TargetConfirmationDialogComponent } from './components/target-confirmation-dialog/target-confirmation-dialog.component';
import { TargetSelectionComponent } from './components/target-selection/target-selection.component';
import { WidgetMainLayoutComponent } from './components/widget-main-layout/widget-main-layout.component';
import { MinutesSecondsPipe } from './pipes/minutes-seconds.pipe';
import { ShowCreditsIconPipe } from './pipes/show-credits-icon.pipe';
import { ShowPointsIconPipe } from './pipes/show-points-icon.pipe';
import { ShowRevenueIconPipe } from './pipes/show-revenue-icon.pipe';

@NgModule({
  declarations: [
    LogoComponent,
    LoadingIndicatorComponent,
    MinutesSecondsPipe,
    ModalOpenPaddingWrapperComponent,
    ImageSliderPuzzleComponent,
    PromotionAnimatedButtonComponent,
    DefaultMainLayoutComponent,
    WidgetMainLayoutComponent,
    CarouselSliderComponent,
    PromotionBoxComponent,
    ActionBoxComponent,
    CustomListComponent,
    CustomProductListComponent,
    AccentRowComponent,
    BackToLinkComponent,
    CustomSelectFormControlComponent,
    BackToTopButtonComponent,
    CustomSalesClaimingListComponent,
    ShowRevenueIconPipe,
    ShowPointsIconPipe,
    ShowCreditsIconPipe,
    CustomTransactionsListComponent,
    SalesAcceleratorListComponent,
    HomeBannerSectionComponent,
    TargetSelectionComponent,
    TargetConfirmationDialogComponent,
    CustomDistributorsSearchListComponent,
    InvoiceUploadFormComponent,
    DownloadProgramGuideComponent,
    SalesTargetListComponent,
    InfoSignBoxComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgxSpinnerModule,
    NgbDropdownModule,
    GenericCarouselModule,
    BannerModule,
    TranslateModule,
    BreadcrumbsModule,
    GenericListModule,
    NgbCarouselModule,
    PipesModule,
    FormModule,
  ],
  exports: [
    LogoComponent,
    LoadingIndicatorComponent,
    MinutesSecondsPipe,
    ModalOpenPaddingWrapperComponent,
    ImageSliderPuzzleComponent,
    PromotionAnimatedButtonComponent,
    DefaultMainLayoutComponent,
    CarouselSliderComponent,
    PromotionBoxComponent,
    ActionBoxComponent,
    CustomListComponent,
    CustomProductListComponent,
    AccentRowComponent,
    BackToLinkComponent,
    CustomSelectFormControlComponent,
    BackToTopButtonComponent,
    CustomSalesClaimingListComponent,
    ShowRevenueIconPipe,
    ShowPointsIconPipe,
    ShowCreditsIconPipe,
    CustomTransactionsListComponent,
    SalesAcceleratorListComponent,
    HomeBannerSectionComponent,
    TargetSelectionComponent,
    TargetConfirmationDialogComponent,
    CustomDistributorsSearchListComponent,
    InvoiceUploadFormComponent,
    DownloadProgramGuideComponent,
    SalesTargetListComponent,
    InfoSignBoxComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor(private iconlibrary: FaIconLibrary) {
    this.iconlibrary.addIcons(faBell, faChevronRight, faMinus, faPlus, faXmark, faChevronUp, faCircleInfo);
  }
}
